export const useApiHeaders = async () => {
  const currentUser = await useCurrentUser();
  const idToken = await currentUser?.value?.getIdToken();

  if (!idToken) {
    throw createError("Unauthorized.");
  }

  const headers = {
    authToken: idToken!,
  } as any;

  const pseudoLoginStore = usePseudoLogin();
  if (pseudoLoginStore.isPseduLogin == true) {
    headers.pseudologinid = pseudoLoginStore.pseudoId;
  }

  return headers;
};
